.helper {
    background-color: var(--menu-bg-color);
    border: 2px solid var(--active-bg-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.helper h1 {
    color: var(--helper-text);
    font-size: 1.1em;
}

.tooltip {
    position: absolute;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: var( --menu-bg-color);
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    pointer-events: none;
    width: 400px;
    border-radius: 10px;
    z-index: 1000 !important;
    display: flex;
    flex-direction: row;
    gap: 1em;
  }

    .tooltip h1 {
        font-size: 1.1em;
        color: var(--menu-headline);
        margin-left: 0em !important;
        text-align: left !important;
    }

    .tooltip p {
        font-size: 1em;
        color: var(--menu-headline);
        margin-left: 0em !important;
        text-align: left !important;
    }

@media only screen and (max-width: 600px) {

    .tooltip {
        width: 87%;
        height: auto;
      }

      
}