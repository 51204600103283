:root {
  --menu-bg-color: #ddf4ff;
  --menu-headline: #044567;
  --active-bg-color: #7fb5cf;
  --active-text-color: #044567;
  --link-color: #676666;
  --mobile-link-color: #044567;
  --active-mobile-icon: none;
  --content-bg: #ffffff;
  --map-owerlay: #ddf4ffbc;
  --map-marker: #6a9cb78a;
  --helper-text: #044567;
  --icon-filter: brightness(130%) contrast(40%) saturate(60%) hue-rotate(30deg);
}

[data-theme="darkk"] { /* original */
  --menu-bg-color: #044567;
  --menu-headline: #ffffff;
  --active-bg-color: #7fb5cf;
  --active-text-color: #044567;
  --link-color: #ffffff;
  --mobile-link-color: #7089a3;
  --content-bg: #1f1f1f;
  --map-owerlay: #1f1f1fbc;
  --map-marker: #7fb4cf8c;
  --helper-text: #7fb5cf;
  --active-mobile-icon: brightness(130%) contrast(30%) saturate(90%)
    hue-rotate(30deg);
  --icon-filter: saturate(0%) brightness(1000%);
}

[data-theme="darkkk"] {
  --menu-bg-color: #2a3d4c; /* Tmavší modrá pro pozadí nabídky */
  --menu-headline: #ddf4ff; /* Světlejší modrá pro nadpisy v nabídce */
  --active-bg-color: #4a6572; /* Středně tmavá modrá pro aktivní prvky */
  --active-text-color: #ddf4ff; /* Světle modrá pro text aktivních prvků */
  --link-color: #a0a0a0; /* Světle šedá pro odkazy */
  --mobile-link-color: #ddf4ff; /* Světle modrá pro mobilní odkazy */
  --active-mobile-icon: none; /* Beze změn */
  --content-bg: #1a202c; /* Velmi tmavá šedá pro pozadí obsahu */
  --map-owerlay: #1f1f1fbc;/* Tmavě modrá s průhledností pro mapový overlay */
  --map-marker: #6a9cb78a; /* Beze změn */
  --helper-text: #ddf4ff; /* Světle modrá pro pomocný text */
  --icon-filter: saturate(0%) brightness(1000%);
}

[data-theme="dark"] {
  --menu-bg-color: #033454; /* Slightly darker blue */
  --menu-headline: #e0e0e0; /* Lighter gray instead of white */
  --active-bg-color: #6fa8c5; /* Softer shade of blue */
  --active-text-color: #033454; /* Consistent with menu background */
  --link-color: #e0e0e0; /* Light gray for links */
  --mobile-link-color: #60788e; /* Slightly darker for mobile links */
  --content-bg: #1f1f1f; /* Slightly lighter dark shade */
  --map-owerlay: #1c1c1cbc; /* Adjusted to match content background */
  --map-marker: #6fa8c58c; /* Softer shade for map marker */
  --helper-text: #6fa8c5; /* Consistent with active background color */
  --active-mobile-icon: brightness(120%) contrast(30%) saturate(90%) hue-rotate(30deg);
  --icon-filter: saturate(0%) brightness(800%); /* Reduced brightness */
}



.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: var(--active-text-color);
  border: 1px double var(--active-text-color);
  color: #fff;
}

.CalendarDay__selected_span {
  background: var(--active-bg-color);
  border: 1px double var(--active-bg-color);
  color: #fff;
}


.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  background: var(--active-bg-color);
  border: 1px double var(--active-bg-color);
  color: #fff;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  background: var(--active-bg-color);
  border: 1px double var(--active-bg-color);
  color: #fff;
}
@media only screen and (max-width: 1100px) {
input[name="selectdate"] {
text-align: center;
padding-top: 1.8em;
}
}