.map {
    position: relative;
}

.map-container {
    width: 100%;
    height: 396px;
    position: relative;
    border-radius: 20px;
    z-index: 1; 
}

.map-owerlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--map-owerlay);
    border-radius: 20px 20px 0 0;
    color: var(--menu-headline);
    padding: 0.5em;
    z-index: 2;
}


.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    display: none;
}