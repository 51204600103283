.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  bottom: 0;
  position: relative;
  width: 100%;
  background-color: var(--menu-bg-color);
  padding: 1em 0;
  border-radius: 15px  15px 0 0 ;
  bottom: 0;
}

.logo img {
  width: 30%;
  width: auto;
  scale: 1.2;
  margin-top: 0.5em;
}

.logo:first-child {
  width: 30%;
  width: auto;
  scale: 1.5;
}



@media only screen and (max-width: 700px) {
  .logo-container {
    flex-wrap: wrap;
  }

  .logo:first-child {
    flex-basis: 80%;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }

  .logo img {
    height: auto;
    max-width: 100%;
    margin: 0.4em;
  }
}
