.menu {
  background-color: var(--menu-bg-color);
  min-width: 19em;
  height: auto;
  min-height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: row;
}

.content {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  width: 100%;
  justify-content: space-between;
  background-color: var(--content-bg);
  padding: 2em 2em 0 2em;
}

.menu h1 {
  padding-top: 2em;
  margin: 0;
  font-size: 2em;
  width: 80%;
  color: var(--menu-headline);
  margin-bottom: 1em;
}

.menu-icon {
  width: 3.3em;
  filter: var(--icon-filter);
}

.menu-mezera {
  border-bottom: 2px solid var(--active-bg-color);
  width: 80%;
  height: 1.5px;
  padding-top: 1.5em;
  margin-bottom: 1.5em;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu-button {
  width: 80%;
  padding: 0.2em 0;
  border: none;
  background-color: transparent;
  text-align: left;
  font-size: 1.1em;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0em;
  padding-left: 1em;
  text-decoration: none;
}

.menu-themeswitcher {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.active {
  background-color: var(--active-bg-color) !important;
  border-radius: 50px;
}

.active p {
  color: var(--active-text-color) !important;
  font-weight: 700 !important;
}

.active img {
  filter: none !important;
}

p {
  text-decoration: none;
  color: var(--link-color);
  font-size: 1.1em;
  font-weight: 600;
}

@media only screen and (max-width: 1000px) {
  .menu {
    width: 100%;
    min-height: auto;
    min-width: 100%;
  }

  .menu h1 {
    padding-top: 1em;
    text-align: center;
    padding-bottom: 0;
  }

  .container {
    flex-direction: column;
  }

  .content {
    height: auto;
    min-height: 100vh;
    width: 100%;
  }

  .menu-buttons {
    flex-direction: row;
    justify-content: center;
  }

  .menu-mezera {
    display: none;
  }

  .menu-button {
    padding: 0;
    font-size: 1.1em;
    flex-direction: column;
    text-align: center;
    width: 20%;
    margin-bottom: 1em;
  }

  .menu-icon {
    width: 5em;
    filter: var(--icon-filter);
  }

  .active {
    border-radius: 0;
    background-color: var(--menu-bg-color) !important;
  }
  
  .active p {
    color: var(--mobile-link-color) !important;
    font-weight: 600 !important;
  }
  
  .active img {
    filter: var(--active-mobile-icon) !important;
  }
  
  p {
    font-weight: 550;
    padding: 0;
    margin: 0;
  }
}

@media only screen and (max-width: 700px) {
  .menu h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 0.7em;
  }

  .menu-icon {
    width: 70%;
  }
}

@media only screen and (max-width: 300px) {
  .menu h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 0.5em;
  }

  .menu-icon {
    width: 70%;
  }
}