* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.countbox2 {
  border: 2px solid #7fb5cf; /* --active-bg-color */
  width: 32%;
  height: 200px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.countbox2-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.countbox2 h2 {
  font-size: 200%;
  font-weight: 600;
  color: #044567; /* --menu-headline */
}

.countbox2 h3 {
  font-size: 100%;
  font-weight: 600;
  color: #044567; /* --menu-headline */
  padding: 0.5em;
  padding: 1em 0 0 1em;
  width: 80%;
}

.countbox2 p {
  font-size: 100%;
  font-weight: 600;
}

.green p {
  color: #06a906 !important;
}

.red p {
  color: #ff0000 !important;
}

.countbox2-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  height: 70%;
}

.countbox2-num {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 1em;
  height: 70%;
}

.countbox2-div img {
  width: 55%;
  margin-right: -5px;
}

.countbox2-helper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5em;
}

.countbox2-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

@media only screen and (max-width: 1700px) {
  .countbox2 h2 {
    font-size: 180%;
  }

  .countbox2-nav h3 {
    font-size: 90%;
  }

  .countbox2 p {
    font-size: 90%;
  }

  .countbox2-div {
    flex-direction: column;
  }

  .countbox2-div img {
    width: 102%;
    margin-right: -10px;
  }
}

@media only screen and (max-width: 800px) {
  .countbox2 {
    padding-top: 0.5em;
    height: 150px;
    align-items: center;
  }

  .countbox2-helper {
    padding: 0 0.5em;
  }

  .countbox2 h2 {
    font-size: 150%;
  }

  .countbox2-nav h3 {
    font-size: 85%;
    text-align: left;
    padding: 0;
    padding-left: 0.5em;
  }

  .countbox2 p {
    font-size: 85%;
  }

  .countbox2-div img {
    display: none;
  }

  .countbox2-div {
    align-items: center;
    border-radius: 0;
  }

  .countbox2-num {
    align-items: center;
    padding: 0;
    margin-top: 1em;
  }
}

@media only screen and (max-width: 500px) {
  .countbox2 {
    border: 2px solid #7fb5cf; /* --active-bg-color */
    width: 100%;
    height: 150px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1em;
  }

  .countbox2 h2 {
    font-size: 200%;
    font-weight: 600;
    color: #044567; /* --menu-headline */
  }

  .countbox2-nav h3 {
    width: 90%;
    margin-left: 5%;
    text-align: left;
  }

  .countbox2 p {
    font-size: 100%;
    font-weight: 600;
  }

  .countbox2-num {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 1em;
    height: 70%;
  }

  .countbox2-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    overflow: hidden;
    border-radius: 0 0 20px 20px;
    height: 70%;
    width: 100%;
  }

  .countbox2-div img {
    display: block;
    width: 50%;
    margin-right: -5px;
  }
}

.iframe-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.iframe-button {
    background-color: white;
    border: 3px solid #7fb5cf; /* --active-bg-color */
    padding: 0.6em;
    border-radius: 25px;
    font-size: 1em;
    color: #7fb5cf; /* --active-bg-color */
    margin-top: 1em;
}

.iframe-button:hover {
    background-color: #7fb5cf; /* --active-bg-color */
    color: white;
    cursor: pointer;
}


.dopravainfo {
  border: 2px solid #7fb5cf; /* --active-bg-color */
  width: 32%;
  height: 200px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dopravainfo h1 {
  font-size: 1.5em;
  color: #044567; /* --menu-headline */
  padding: 0.5em 0 0 0.6em;
  margin: 0;
}

.dopravainfo .coll {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5em;
  margin: 0.5em 0 0 0.5em;
}

.dopravainfo .coll h2 {
  font-size: 1.5em;
  font-weight: 700;
  color: #044567; /* --menu-headline */
}

.dopravainfo .coll p {
  font-size: 0.9em;
  color: #044567; /* --menu-headline */
  font-weight: 600;
}

.dopravainfo .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  width: 100%;
  color: #4b8c2d !important;
}

.dopravainfo .row h2 {
  color: #4b8c2d !important;
}

.dopravainfo .row img {
  width: 2em;
  margin-top: 0.3em;
}