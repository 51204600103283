* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.countbox {
  border: 2px solid var(--active-bg-color);
  width: 32%;
  height: 200px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.countbox-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.countbox h2 {
  font-size: 200%;
  font-weight: 600;
  color: var(--menu-headline);
}

.countbox h3 {
  font-size: 100%;
  font-weight: 600;
  color: var(--menu-headline);
  padding: 0.5em;
  padding: 1em 0 0 1em;
  width: 80%;
}

.countbox p {
  font-size: 100%;
  font-weight: 600;
}

.green p {
  color: #06a906 !important;
}

.red p {
  color: #ff0000 !important;
}

.countbox-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  height: 70%;
}

.countbox-num {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 1em;
  height: 70%;
}

.countbox-div img {
  width: 55%;
  margin-right: -5px;
}

.countbox-helper{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /*z-index: 1000; */
  padding: 0.5em;
}

.countbox-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

@media only screen and (max-width: 1700px) {
  .countbox h2 {
    font-size: 180%;
  }

  .countbox-nav h3 {
    font-size: 90%;
  }

  .countbox p {
    font-size: 90%;
  }

  .countbox-div {
    flex-direction: column;
  }

  .countbox-div img {
    width: 102%;
    margin-right: -10px;
  }

}

@media only screen and (max-width: 800px) {
  .countbox {
    padding-top: 0.5em;
    height: 150px;
    align-items: center;
  }

  
.countbox-helper{
  padding: 0  0.5em;
}


  .countbox h2 {
    font-size: 150%;
  }

  .countbox-nav h3 {
    font-size: 85%;
    text-align: left;
    padding: 0;
    padding-left: 0.5em;
  }

  .countbox p {
    font-size: 85%;
  }

  .countbox-div img {
    display: none;
  }

  .countbox-div {
    align-items: center;
    border-radius: 0;
  }

  .countbox-num {
    align-items: center;
    padding: 0;
    margin-top: 1em;
  }
}

@media only screen and (max-width: 500px) {

  .countbox {
    border: 2px solid var(--active-bg-color);
    width: 100%;
    height: 150px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1em;
  }
  
  .countbox h2 {
    font-size: 200%;
    font-weight: 600;
    color: var(--menu-headline);
  }
  
  .countbox-nav h3 {
    width: 90%;
    margin-left: 5%;
    text-align: left;
  }
  
  .countbox p {
    font-size: 100%;
    font-weight: 600;
  }
  
  .countbox-num {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 1em;
    height: 70%;
  }

  .countbox-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    overflow: hidden;
    border-radius: 0 0 20px 20px;
    height: 70%;
    width: 100%;
  }
  

  .countbox-div img {
    display: block;
    width: 50%;
    margin-right: -5px;
  }

}



