.serach {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    border-radius: 50px;
    background-color: transparent;
    border: 2px solid var(--active-bg-color);
  }
  
  .serach input {
    width: 80%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding-left: 1em;
    font-size: 1.1em;
    color: var(--menu-headline);
  }
  
  .serach input:focus {
    outline: none;
  }
  
  .serach img {
    filter: var(--icon-filter);
    width: 4em;
  }
  

  @media only screen and (max-width: 500px) {
    .serach {
      height: 3.5em;
    }
    
    .serach input {
      padding-left: 1em;
      font-size: 1em;
    }

    .serach img {
      width: 3em;
    }
}

