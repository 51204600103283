.page404 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    height: 80%;
    gap: 1em;
}

.page404 h1 {
    font-size: 3em;
    color: var(--menu-headline);
}

.page404 p {
    font-size: 1em;
    color: var(--menu-headline);
}