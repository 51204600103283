.rychlosti-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.rychlosti {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  max-width: 2000px;
  width: 100%;
}

.rychlosti-select {
  color: var(--menu-headline) !important;
  background-color: var(--menu-bg-color);
  border: none;
}

.rychlosti-slects {
  width: auto;
  gap: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rychlosti-nav {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.rychlosti-serach {
  width: 20em;
}

.rychlosti-date {
  width: 20em;
  height: 3em;
  border: none;
  border-radius: 10px;
  background-color: var(--menu-bg-color);
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.1em;
  color: var(--menu-headline);
  border-radius: 10px;
  outline: none;
}

.rychlosti-refresh {
  width: 20em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rychlosti-bigchart-container {
  width: 100%;
  height: 25em;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border: 2px solid var(--active-bg-color);
  border-radius: 20px;
}

.rychlosti-bigchart {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.rychlosti-chart-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.rychlosti-chart-nav p {
  margin-left: 1em;
}

.rychlosti-helper {
  margin-right: 1em;
}

.rychlosti-chart-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 20%;
  height: 100%;
}

.rychlosti-chart-numbers-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  gap: 1em;
  height: 100%;
}

.rychlosti-chart-numbers-item h1 {
  height: auto;
}

.rychlosti-piechart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 25em;
  border: 2px solid var(--active-bg-color);
  border-radius: 10px;
  padding-top: 1em;
}

.rychlosti-smallchart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 69%;
  height: 25em;
  border: 2px solid var(--active-bg-color);
  border-radius: 10px;
  padding-top: 1em;
}

.rychlosti-chartcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1%;
  flex-direction: row;
  margin-bottom: 3em;
}

.rychlosti-navrow {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  gap: 1em;
}

.rychlosti-collumnav {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1338px) {

  .rychlosti-navrow {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    gap: 1em;
  width: 100%;
  }
  
  .rychlosti-collumnav {
    display: flex;
    flex-direction: column;
  width: 100%;
  }

  .rychlosti-collumnav input{
  width: 100%;
  }

  .rychlosti-bigchart {
    width: 100%;
  }

  .rychlosti-bigchart p {
    margin: 1em;
  }

  .rychlosti-bigchart-container {
    flex-direction: column;
    height: 30em;
  }

  .rychlosti-chart-numbers {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  .rychlosti-chartcontainer {
    flex-direction: column;
  }

  .rychlosti-chart-numbers-item {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
  }

  .rychlosti-chart-numbers-item h1 {
    font-size: 150%;
  }

  .rychlosti-smallchart {
    width: 100%;
    margin-top: 0.5em;
  }

  .rychlosti-piechart {
    width: 100%;
  }

  .rychlosti-chartcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1%;
    flex-direction: column;
  }

  .rychlosti-serach {
    width: 100%;
  }

  .rychlosti-nav {
    flex-direction: column-reverse;
    gap: 2em;
    margin-top: 1em;
    text-align: center;
  }

  .rychlosti-refresh {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }

  .rychlosti-slects {
    flex-direction: column;
    width: 100%;
  }

  .rychlosti-select {
    width: 100%;
  }

  .rychlosti-date {
    width: 100%;
  }  
}