.krizovtaky-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.krizovatky {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  max-width: 2000px;
  width: 100%;
}

.krizovatky-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1em;
  margin-top: 1em;
}

.krizovatky-row2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1em;
}

.krizovatky-collum {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}

.krizovatky-collum p {
  margin: 0.5em 0 0 1em;
}

.invisible {
  display: none;
}

.krizovatky-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.krizovatky-chart-container {
  width: 100%;
  height: 25em;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--active-bg-color);
  border-radius: 20px;
}

.krizovatky-chart {
  width: 70%;
  height: 25em;
  display: flex;
  flex-direction: row;
}

.krizovatky-helperchart {
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.krizovatky-helperchart .helper {
  margin: 0.5em;
}

.krizovatky-map {
  width: 100%;
  height: 400px;
  margin: 1em 0;
  border: 2px solid var(--active-bg-color);
  border-radius: 20px;
}

.krizovatky-serach {
  width: 20em;
}

.custom-icon .circle {
  width: 15px;
  height: 15px;
  background-color: var(--map-marker);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: 1px solid var(--menu-headline);
}

.custom-icon .circle-radius {
  width: 30px;
  height: 30px;
  background-color: var(--map-marker);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border: 1px solid var(--menu-headline);
}

.krizovatky-select {
  color: var(--menu-headline) !important;
  background-color: var(--menu-bg-color);
  border: none;
  width: 100%;
}

.krizovatky-nav {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  text-align: center;
}

.krizovatky-lastnav {
  margin-bottom: 2em;
  gap: 1em;
}

.krizovatky-radio {
  color: var(--menu-headline) !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  color: var(--menu-headline) !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: var(--menu-headline) !important;
}
.krizovatky-refresh {
  width: 20em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.krizovatky-slects {
  width: auto;
  gap: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.krizovatky-date {
  width: 20em;
  height: 3em;
  border: none;
  border-radius: 10px;
  background-color: var(--menu-bg-color);
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.1em;
  color: var(--menu-headline);
  border-radius: 10px;
  outline: none;
}

.krizovatky-countboxs {
  width: 25%;
  height: auto;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2em;
}

.krizovatky-count-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.krizovatky-count-box-text {
  font-size: 1.3em;
  text-align: left;
}

.krizovatky-count-box-count {
  font-size: 2.3em;
  font-weight: bold;
  color: var(--menu-headline);
  text-align: left;
}

@media only screen and (max-width: 1361px) {
  .krizovatky-count-box-text {
    font-size: 1em;
  }

  .krizovatky-count-box-count {
    font-size: 2em;
  }

  .krizovatky-nav {
    flex-direction: column;
  }

  .krizovatky-slects {
    flex-direction: column;
    width: 100%;
  }

  .krizovatky-input input {
    width: 100%;
    height: 3em;
  }

  .krizovatky-input {
    width: 100%;
  }

  .krizovatky-collum {
    width: 100%;
  }

  .krizovatky-nav .krizovatky-lastnav {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .krizovatky-serach {
    width: 100%;
  }

  .krizovatky-nav {
    flex-direction: column-reverse;
    gap: 2em;
    margin-top: 1em;
  }

  .krizovatky-refresh {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }

  .krizovatky-slects {
    flex-direction: column;
    width: 100%;
  }

  .krizovatky-select {
    width: 100%;
  }

  .krizovatky-date {
    width: 100%;
  }

  .krizovatky-chart-container {
    height: auto;
    flex-direction: column;
  }

  .krizovatky-chart {
    width: 100%;
  }

  .krizovatky-countboxs {
    width: 100%;
    flex-direction: row;
  }
}

@media only screen and (max-width: 700px) {
  .krizovatky-countboxs {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
