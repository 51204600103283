.vozidla-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.vozidla {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  max-width: 2000px;
  width: 100%;
}

.vozidla-daypicker {
  position: absolute;
  z-index: 10000;
}

.vozidla-navnav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  text-align: left;
}

.vozidla-navnav p {
  width: auto !important;
  margin: 0;
}

.vozidla-navnavnav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.vozidla-chartnav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.vozidla-chartnav p {
  margin-left: 1em;
}

.vozidla-refresh {
  width: 20em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.vozidla-piechart-check {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  color: var(--menu-headline);
}

.vozidla-helper {
  margin-right: 1em;
}

h1 {
  font-size: 2em;
  color: var(--menu-headline);
  margin-bottom: 1em;
}

.vozidla-treemap-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5em;
  margin: 0.5em;
}

.vozidla-treemap-div {
  width: 13em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vozidla-treemap-div p {
  width: 100% !important;
  text-align: left;
  margin-bottom: 0 !important;
  margin-left: 0.5em !important;
}

.vozidla-ukazatel {
  border-radius: 50%;
  width: 15px;
  height: 13px;
}

.vozidla p {
  text-align: left;
  width: 90%;
  margin-bottom: 0.2em;
}

.vozidla-serach {
  width: 20em;
}

.vozidla-nav {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
  text-align: center;
}

.vozidla-date {
  width: 20em;
  height: 3em;
  border: none;
  border-radius: 10px;
  background-color: var(--menu-bg-color);
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.1em;
  color: var(--menu-headline);
  border-radius: 10px;
  outline: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  width: 1.5em;
  height: 1.5em;
}

.vozidla-chartcontainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 1%;
}

.vozidla-simplechart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 69%;
  height: 25em;
  border: 2px solid var(--active-bg-color);
  border-radius: 10px;
  padding-top: 1em;
  padding-right: 1em;
}

.vozidla-piechart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 25em;
  border: 2px solid var(--active-bg-color);
  border-radius: 10px;
  padding-top: 1em;
}

.vozidla-barchart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 49%;
  height: 25em;
  border: 2px solid var(--active-bg-color);
  border-radius: 10px;
  padding-top: 1em;
  padding-right: 1em;
  margin-bottom: 3em;
}

.vozidla-treemap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 25em;
  border: 2px solid var(--active-bg-color);
  border-radius: 10px;
  padding: 5px;
  padding-top: 1em;
  margin-bottom: 3em;
}

@media only screen and (max-width: 1700px) {
  .vozidla-treemap {
    height: 25em;
  }

  .vozidla-barchart {
    height: 25em;
  }

  @media (min-height: 25em) {
    .vozidla-treemap {
      height: auto;
    }

    .vozidla-barchart {
      height: 25em;
    }
  }
}

@media only screen and (max-width: 1100px) {

  p {
    font-size: 1em;
  }

  .vozidla-navnav {
    flex-direction: column;
    gap: 1em;
    width: 100%;
  }

  .vozidla-navnavnav {
    width: 85vw;
  }

  .vozidla-date {
    width: 80%;
    height: 3em;
  }

  .vozidla-treemap-div {
    width: 45%;
    justify-content: flex-start;
  }

  .vozidla-treemap-div p {
    width: auto !important;
  }

  .vozidla-ukazatel {
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }

  .vozidla-refresh {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }

  .vozidla-chartcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1%;
    flex-direction: column;
  }

  .vozidla {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .vozidla-simplechart {
    width: 100%;
    height: 25em;
    margin: 1em 0;
  }

  .vozidla-piechart {
    width: 100%;
    height: 25em;
    margin: 1em 0;
  }

  .vozidla-barchart {
    width: 100%;
    height: 25em;
    margin: 1em 0;
  }

  .vozidla-treemap {
    width: 100%;
    margin: 1em 0;
  }

  .vozidla-serach {
    width: 100%;
  }

  .vozidla-nav {
    gap: 2em;
    margin-top: 1em;
    margin-bottom: 0;
    flex-direction: column-reverse;
  }

  .vozidla-date { 
    padding-bottom: 2em;
  }
}


@media only screen and (max-width: 420px) {

  p {
    font-size: 0.7em;
  }

  .vozidla-chartnav {
    gap: 1em;
  }
}