.dashboard-big-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dashboard-container {
  max-width: 2000px;
  width: 100%;
}

.dashboard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.logo-bilina {
  margin: 1em 1em 3em 1em;
  width: 10em;
}

.dashboard-countboxs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dashboard-mapa {
  width: 100%;
  height: 400px;
  margin: 1em 0;
  border: 2px solid var(--active-bg-color);
  border-radius: 20px;
}

.dashboard-container-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
}

.dashboard-container-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 30%;
}

.dashboard-calendar {
  border: 2px solid var(--menu-bg-color);
  border-radius: 20px;
  background-color: var(--menu-bg-color) !important;
  color: var(--menu-headline) !important;
  width: 80%;
  margin: 0;
}

.dashboard-calendar button {
  color: var(--menu-headline) !important;
}

.dashboard-calendar span {
  color: var(--menu-headline) !important;
}

.dashboard-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
}

.dashboard-helper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding: 1em;
  margin-top: -1em;
}

.dashboard-nav {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1em;
}

.dashboard-serach {
  width: 20em;
}

.css-1aj9me-MuiPickersLayout-root {
  display: block !important;
}

.dashboard-refresh {
  width: 20em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dashboard-date {
  width: 90%;
  height: 3em;
  border: none;
  border-radius: 10px;
  background-color: var(--menu-bg-color);
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.1em;
  color: var(--menu-headline);
  border-radius: 10px;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.recharts-tooltip-wrapper {
  background-color: var(--menu-bg-color) !important;
}


.recharts-default-tooltip p  {
  color: var(--menu-headline) !important;
}

.recharts-default-tooltip   li  {
  color: var(--menu-headline) !important;
}

@media only screen and (max-width: 1600px) {
  .dashboard-refresh {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1200px) {
  .logo-bilina {
    padding: 1em 0;
    margin: 0;
  }

  .dashboard-date {
    width: 100%;
  }

  .dashboard-nav {
    margin-top: 1em;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .dashboard-serach {
    width: 100%;
    margin-bottom: 5%;
  }

  .logo-bilina {
    width: 50%;
  }

  .dashboard {
    align-items: center;
    flex-direction: column;
  }

  .dashboard-container-left,
  .dashboard-container-right {
    width: 100%;
  }

  .dashboard-countboxs {
    width: 100%;
  }

  .dashboard-mapa {
    width: 100%;
    height: 400px;
  }

  .dashboard-refresh {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }
}


@media only screen and (max-width: 500px) {
    .dashboard-countboxs{
        flex-direction: column;
        
    }

}