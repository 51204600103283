.refresh {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5em;
    border-radius: 10px;
    background-color: var(--menu-bg-color);
    margin-top: 1em;
}

.refresh img {
    width: 5em;
    filter: var(--icon-filter);
    padding-left: 1em;
}

.refresh-texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100%;
    padding-left: 1em;
}

.refresh-texts h4 {
    font-size: 1em;
    font-weight: 600;
    color: var(--menu-headline);
}

.refresh-texts p {
    font-size: 1em;
    font-weight: 600;
    color: var(--menu-headline);
}

.refresh :hover {
    cursor: pointer;
}

@media only screen and (max-width: 1500px) {
    .refresh {
        height: 6em;
    }
}